<template>
  <div class="overflow-scroll">
    <a-card style="min-width: 1150px">
      <div class="table-operator">
        <a-form layout="inline" class="stallsEditLabelWidth">
          <a-row :gutter="0" style="line-height: 3.5">
            <a-col :md="8" :sm="24">
              <a-form-item label="账单ID">
                <a-input v-model="queryParam.code" style="width: 240px" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="门店编号">
                <a-input v-model="queryParam.facilityCode" style="width: 240px" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="门店名称">
                <a-input v-model="queryParam.facilityName" style="width: 240px" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名称">
                <a-input style="width: 240px" v-model="queryParam.storeName" placeholder="请输入" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="账单类型">
                <a-select
                  style="width: 240px"
                  v-model="queryParam.billType"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in billTypeList" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="账单周期">
                <a-month-picker
                    :allowClear="false"
                    :value="queryParam.momentDate"
                    @change="searchMonth"
                    placeholder="选择月份"
                />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="确认状态">
                <a-select
                  style="width: 240px"
                  v-model="queryParam.confirmStatus"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="item in BillConfirmList" :key="item.id">{{ item.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="支付状态">
                <a-select
                  style="width: 240px"
                  v-model="queryParam.payStatus"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option v-for="(value, key) in payStatusMap" :key="key">{{ value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="需财务人工确认">
                <a-select
                  style="width: 240px"
                  v-model="queryParam.enableConfirm"
                  allowClear
                  placeholder="请选择"
                  default-value="Y"
                >
                  <a-select-option value="Y">需要确认</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="是否需支付">
                <a-select
                  style="width: 240px"
                  v-model="queryParam.isCanPay"
                  allowClear
                  placeholder="请选择"
                >
                  <a-select-option value="1">是</a-select-option>
                  <a-select-option value="0">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 7) || 24" :sm="24" class="fr">
              <span
                class="table-page-search-submitButtons"
                :style="
                  (advanced && { float: 'right', overflow: 'hidden' }) || {}
                "
              >
                <a-button type="primary" @click="search" :loading="loading">查询</a-button>
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.search())"
                >重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <a-spin :spinning="loading">
        <a-table
          rowKey="code"
          :pagination="pagination"
          :columns="columns"
          :dataSource="data"
          :scroll="{ x: 1700 }"
        >
          <span slot="customTitle">
            <a-icon type="smile-o" />Name
          </span>
          <span slot="start" slot-scope="text, record">{{ record.start }} — {{ record.end }}</span>
          <span slot="action" slot-scope="text, record" class="blueColor">
            <a @click="handleDetail(record)">账单明细</a>
          </span>
        </a-table>
        <token-pagination
            :page="page"
            :total="total"
            :pageSize="pageSize"
            :nextToken="nextToken"
            @change="changePage"
        />
      </a-spin>
    </a-card>
  </div>
</template>

<script>
  

import moment from "moment";
import Bus from "@/bus";
import { tokenOnlyPagination } from "@/utils/pagination";
import { mapActions, mapGetters } from "vuex";
import TokenPagination from "@/components/Pagination/TokenPagination";
import { GET_BILLS, RESET_BILLS } from "@/store/modules/actions.type";

export default {
  name: "financeBail",
  components: {
    TokenPagination
  },
  data() {
    return {
      moment,
      visible: false,
      advanced: false,
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this),
      queryParam: {
        momentDate: null
      },
      labelCol: { xs: { span: 24 }, sm: { span: 7 }, },
      wrapperCol: {  xs: { span: 24 }, sm: { span: 13 }, },
      style: "",
      showDetail: false,
      pagination: tokenOnlyPagination(),
      page: 1,
      pageSize: 20,
      columns: [
        {
          title: "账单ID",
          dataIndex: "code",
          key: "code",
          width: 160,
        },
        {
          title: "门店编号",
          dataIndex: "facilityCode",
          key: "facilityCode",
        },
        {
          title: "门店名称",
          dataIndex: "facilityName",
          key: "facilityName",
        },
        {
          title: "档口编号",
          dataIndex: "kitchenName",
          key: "kitchenName",
        },
        {
          title: "店铺名称",
          dataIndex: "storeName",
          key: "storeName",
        },
        {
          title: "账单类型",
          dataIndex: "billTypeName",
          key: "billTypeName",
        },
        {
          title: "账单总金额",
          dataIndex: "billAmount",
          key: "billAmount",
        },
        {
          title: "账单日期",
          dataIndex: "billDate",
          key: "billDate",
        },
        {
          title: "账单周期",
          width: 220,
          customRender: (text, row) => {
            if (row.cycleStartDateLg) {
              return (
                  <span>
                  {moment(row.cycleStartDateLg).format("YYYY-MM-DD")}~
                    {moment(row.cycleEndDateLg).format("YYYY-MM-DD")}
                </span>
              );
            } else {
              return "";
            }
          }
        },
        {
          title: "确认状态",
          dataIndex: "confirmStatusName",
          key: "confirmStatusName",
        },
        {
          title: "支付状态",
          dataIndex: "payStatus",
          key: "payStatus",
          customRender: (text, row) => {
            let payStatus = this.payStatusMap[row.payStatus];
            if (payStatus !== undefined) {
              return payStatus;
            } else {
              return "";
            }
          }
        },
        {
          title: "是否需支付",
          dataIndex: "isCanPayName",
          key: "isCanPayName",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "100px",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      BillConfirmList: [
        { id: 1, name: "待确认" },
        { id: 2, name: "系统自动确认" },
        { id: 3, name: "商户手动确认" },
        { id: 4, name: "标记延缓确认" },
      ],
      billTypeList: [
        { id: 0, name: "全部" },
        { id: 1, name: "首期款账单" },
        { id: 2, name: "改造费用账单" },
        { id: 3, name: "退租结算账单" },
        { id: 4, name: "基础服务费账单" },
        { id: 5, name: "经营费用账单" },
      ],
      payStatusMap:{
        1: "未支付",
        2: "部分支付",
        3: "已支付",
        4: "已支付，待确认",
      },
      financeBailDetail: "",
      data:[]
    };
  },
  created() {
    this.fetch();
    Bus.$on("getTarget", (target) => {
      this.fetch();
    });
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    ...mapGetters("bills", ["total", "bills", "nextToken"]),
    // data: function() {
    //   return this.bills(this.page);
    // },
  },
  watch: {
    page: {
      handler: "fetch"
    }
  },
  methods: {
    ...mapActions("bills", [GET_BILLS, RESET_BILLS]),
    goFinanceBailDetail() {
      this.showDetail = false;
    },
    changePage(page) {
      this.page = page;
    },
    fetch: async function () {
      this.loading = true;
      await this.getBills({
        ...this.queryParam,
        rows: this.pageSize,
        page: this.page,
      });
      this.data = this.bills(this.page);
      this.loading = false;
    },
    showModal() {
      this.visible = true;
    },
    handleDetail(val) {
      if (val.billType === 3) {
        this.$router.push({
          path: "/finance/financeBail/finSettlementDetail",
          query: {
            financeBailDetailId: val.id,
            finbillType: val.billType,
          },
        });
      } else if (val.billType === 5) {
        this.$router.push({
          path: "/finance/financeBail/finOperateDetail",
          query: {
            financeBailDetailId: val.id,
            finbillType: val.billType,
          },
        });
      } else if (val.billType === 4) {
        this.$router.push({
          path: "/finance/financeBail/finRentDetail",
          query: {
            financeBailDetailId: val.id,
            finbillType: val.billType,
          },
        });
      } else if (val.billType === 1) {
        this.$router.push({
          path: "/finance/financeBail/finDownPaymentDetail",
          query: {
            financeBailDetailId: val.id,
            finbillType: val.billType,
          },
        });
      } else if (val.billType === 2) {
        this.$router.push({
          path: "/finance/financeBail/finBuildDetail",
          query: {
            financeBailDetailId: val.id,
            finbillType: val.billType,
          },
        });
      } else {
        this.$router.push({
          path: "/finance/financeBail/financeBailDetail",
          query: {
            financeBailDetailId: val.id,
            finbillType: val.billType,
          },
        });
      }
    },
    search() {
      this.resetBills();
      this.page = 1;
      this.fetch();
    },
    handleEnter() {},
    handleSubmit(val) {
      const {
        form: { validateFields },
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          setTimeout(() => {
            this.visible = false;
            this.confirmLoading = false;
            // this.$emit("ok", values);
          }, 1500);
        } else {
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.edit = false;
    },
    searchMonth(month, monthString) {
      this.queryParam.billDate = monthString;
      this.queryParam.momentDate = moment(monthString);
    },
  },
};
</script>

<style scoped>
.fr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ant-select-selection {
  border-radius: 16px;
  /*border: 1px solid #d9d9d9;*/
}
/*button{*/
/*  border-radius: 16px;*/
/*}*/
</style>
